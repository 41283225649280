<template>
  <div>
    <div class="table-operator" style="margin-bottom: 15px">
      <a-button type="primary" icon="plus" @click="modalAddEditLoad()">添加</a-button>
    </div>

    <s-table
      ref="pageTable"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data="dataset"
      :pagination="pagination"
      :pageSize="10"
    >
      <a slot="name" slot-scope="text">{{ text }}</a>
      <span slot="operation" slot-scope="text, record">
        <a @click="modalAddEditLoad(record)">查看</a> |
        <a-popconfirm
          title="您确认要删除此条记录吗？"
          ok-text="确认"
          cancel-text="取消"
          @confirm="handleRemove(record)"
        >
          <a>删除</a>
        </a-popconfirm>
      </span>
    </s-table>

    <customer-price-form
      :visible="modalAddEditVisible"
      :record="modalAddEditRecord"
      :confirm="modalAddEditConfirm"
      :cancel="modalAddEditCancel"
      >
    </customer-price-form>
  </div>
</template>
<script>
import { STable } from '@/components'
import { customerPriceList, customerPriceRemove } from '@/api/quotation'
import CustomerPriceForm from './module/CustomerPriceForm'

const columns = [
  {
    key: 'name',
    dataIndex: 'name',
    title: '名称',
    align: 'center',
  },
  {
    key: 'updateDate',
    dataIndex: 'updateDate',
    title: '更新时间',
    align: 'center',
  },
  {
    key: 'updateName',
    dataIndex: 'updateName',
    title: '操作人',
    align: 'center',
  },
  {
    key: 'operation',
    dataIndex: 'operation',
    title: '操作',
    align: 'center',
    scopedSlots: {
      customRender: 'operation',
    },
  },
]

export default {
  name: 'TableList',
  components: {
    STable,
    CustomerPriceForm,
  },
  data() {
    return {
      columns: columns,
      dataset: (parameter) => {
        parameter.customerType = this.customerType
        return customerPriceList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            if (0 == res.code) {
              return res.result
            }
          })
          .catch((e) => {
            console.info(e)
            this.$message.error('数据加载失败，请稍后重试')
          })
      },
      pagination: { pageSizeOptions: ['10', '20', '50'] },
      queryParam: {},
      customerType: 2,
      modalAddEditVisible: false,
      modalAddEditRecord: null,
    }
  },
  methods: {
    modalAddEditLoad(record = null) {
      this.modalAddEditVisible = true
      this.modalAddEditRecord = record
    },
    modalAddEditConfirm(submitState, result) {
      if (submitState) {
        this.$message.success('保存成功')
        this.modalAddEditVisible = false
        this.$refs.pageTable.refresh(true)
      } else {
        this.$message.error('保存失败')
      }
    },
    modalAddEditCancel() {
      this.modalAddEditVisible = false
    },
    handleRemove(record) {
      customerPriceRemove(record)
        .then((res) => {
          if (0 == res.code) {
            this.$message.success('删除成功')
            this.$refs.pageTable.refresh(true)
          } else {
            this.$message.error('删除失败')
          }
        })
        .catch((e) => {
          console.info(e)
          this.$message.error('删除失败，请稍后重试')
        })
    },
  },
}
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100%;
}
.ant-form{
  /deep/ .ant-form-item-children{
    display: flex;
    .ant-input-number{
      border-radius: 5px 0 0 5px;
    }
    .percentage{
      border-radius:0 5px 5px 0;
      border-left: 0;
    }
  }
}
/deep/ .ant-form-item-label{
  text-align: left !important;
}
/deep/ .ant-table-tbody{
  background-color: #fff;
}
/deep/ .ant-table-pagination {
 > li{
  background-color: #fff !important;
  min-width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px;
  box-sizing: border-box;
  >div{
    margin: 0 0;
    >div{
      border: none;
    }
   }
  }
  .ant-pagination-options{
   border:1px solid #d9d9d9;
  .ant-select-selection--single {
    height: 32px;
    .ant-select-selection__rendered{
      line-height: 35px;
    }
  }
  }
  .ant-pagination-item-active{
    border-color: #1890ff !important;
  }
}
/deep/ .ant-table-pagination {
 > li{
  background-color: #fff !important;
  min-width: 35px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px;
  box-sizing: border-box;
  >div{
    margin: 0 0;
    >div{
      border: none;
    }
   }
  }
  .ant-pagination-options{
   border:1px solid #d9d9d9;
  .ant-select-selection--single {
    height: 32px;
    .ant-select-selection__rendered{
      line-height: 35px;
    }
  }
  }
  .ant-pagination-item-active{
    border-color: #1890ff !important;
  }
}
/deep/ .ant-form-item-control-wrapper{
  width: 75%;
  float: right;
}
/deep/  .form-submit {
    margin-bottom: 0;
  .ant-form-item-control-wrapper{
    width: 100% !important;
    text-align: right;
  }
  .ant-form-item-children {
    justify-content: flex-end;
  }
}
</style>